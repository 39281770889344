<template>
  <div>
    <div class="d-flex align-items-center justify-content-center h-100 flex-wrap" :class="{'p-custom': !is_in_analyzer}">
      <div class="h-90 position-relative d-flex justify-content-between w-100">
        <div class="d-flex w-100">
          <feather-icon icon="SearchIcon" class="d-block margin-08" size="24" v-if="!is_in_analyzer"></feather-icon>
          <vue-autosuggest
            :suggestions="audience_locations"
            :limit="10"
            :input-props="input_locations"
            :key="change_language"
            @input="inputAudienceLocation"
            @blur.stop="blurActionSuggestion"
            v-model="audience_location"
            :get-suggestion-value="getAudienceLocation"
            :should-render-suggestions="shouldRenderSuggestions"
            ref="vue_autosuggest_input"
            :class="{'outstanding-input-suggestions': is_in_analyzer}"
            class="input-suggestions-audience-locations w-100"
          > 
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item avenir-medium">{{ suggestion.item.name }}</span>
            </template>
  
            <template slot="before-suggestions">
              <div class="m-1" v-if="audience_location !== ''">
                {{$t("search.searchingBy")}}: {{audience_location}}
              </div>
            </template>
            <template slot="after-suggestions">
              <div class="m-1 avenir-medium" v-if="no_locations_audience">
                {{$t("search.noResults")}}
              </div>
            </template>
          </vue-autosuggest>
        </div>
        <div class="d-flex">
          <b-spinner
            v-if="show_loader && loading_location_audience"
            variant="primary"
            label="Text Centered"
            class="spinner-audience"
          />
          <div v-if="!loading_location_audience" class="d-flex position margin-08">
            <feather-icon icon="XIcon" class="d-block cursor-pointer" size="16" v-show="show_reset_location && !loading_location_audience && audience_location" @click="resetAudienceLocation()"></feather-icon>
            <div class="d-flex pl-1">
              <b-icon v-if="show_remove" class="h-90 cursor-pointer" icon="trash" @click="removeLocation()"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100" v-if="show_slider && selected_audience_location">
        <p class="avenir-medium m-0 mt-2 mb-1"> {{ $t('search.moreOf') }} <strong>{{ percentage_audience_location }}</strong>% {{ $t('search.of_audience') }}</p>
        <vue-slider
          :tooltip="'none'"
          class="mb-2"
          v-model="percentage_audience_location"
          :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :max="99"
          :min="1"
          :interval="1"
          @drag-end="changeSlide"
        >
        </vue-slider>
      </div>
    </div>
    <div class="w-100 mt-1" v-if="audience_location.length === 0 && !is_in_analyzer">
      <div v-for="location in main_locations_filter" :key="location.geonameid" class="item-list-main-location" @click="setCountryRecommend(location)">
        {{ location.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner, 
  BIcon
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import VueSlider from 'vue-slider-component'
import axios from 'axios'
import config from '@/services/config'

export default {
  name: 'AudienceLocationFilter',
  components: {
    BSpinner,
    VueAutosuggest,
    VueSlider,
    BIcon
  },
  props: {
    show_remove: {
      required: false,
      default: false
    },
    show_slider: {
      required: false,
      default: true
    },
    location: {
      required: true,
    },
    index: {
      required: true
    },
    show_reset_location: {
      type: Boolean,
      default: true
    },
    show_loader: {
      type: Boolean,
      default: true
    },
    only_countries: {
      type: Boolean,
      default: false
    },
    audience_location_dad: {
      type: String
    },
    is_in_analyzer: {
      type: Boolean,
      default: false,
    },
    main_locations_filter: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      loading_location_audience: false,
      audience_locations: [],
      change_language: false,
      no_locations_audience: false,
      cancel_audience_location: null,
      audience_location: '',
      selected_audience_location: null,
      percentage_audience_location: 10,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      input_suggestion: null
    }
  },
  created () {
    if (this.is_in_analyzer) {
      setTimeout(() => {
        if (this.input_suggestion) this.input_suggestion.focus()
      }, 200);
    }
    if (this.location.id) {
      this.audience_location = this.location.name
      this.selected_audience_location = this.location
    }
  },
  mounted() {
    if (this.is_in_analyzer) {
      // For getting the input DOM, for handling focus event later
      this.$nextTick(() => {
        this.input_suggestion = this.$el.querySelector('input');
      })
    }
  },
  methods: {
    removeLocation () {
      this.$emit('removeAudienceLocationChild', this.selected_audience_location, this.index, true)
    },
    blurActionSuggestion() {
      if (this.is_in_analyzer) {
        setTimeout(() => {
          this.$emit('blur_action')
        }, 200);
      }
    },
    inputAudienceLocation (text) {
      this.audience_locations = []
      if (text !== '') {
        this.loading_location_audience = true;
        this.no_locations_audience = true;
        const CancelToken = axios.CancelToken;

        if (this.cancel_audience_location) this.cancel_audience_location()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/ubicaciones`,
          data:{
            query: text,
            only_country: this.only_countries ? 1 : 0
          },
          headers: {
            'brandme-key': process.env.VUE_APP_BRANDME_KEY,
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_audience_location = c;
          })
        })
          .then(result => {
            let { cities, states, countries } = result.data.data
            cities = cities ? cities : []
            states = states ? states : []
            countries = countries ? countries : []
            self.datasuggest = [
              ...cities.map(city => {
                let name = city.name
                name = `${city.name}, ${city.country.name}`
                return {id:city.geonameid, name, type: 1 }
              }),
              ...states.map(state => {
                let name = state.name
                name = `${state.name}, ${state.country.name}`
                return {id:state.geonameid, name, type: 2 }
              }),
              ...countries.map(country => ({id:country.geonameid, name:country.name, type: 0}))
            ]
            const filteredData = self.datasuggest
            this.no_locations_audience = filteredData.length < 1;
            this.loading_location_audience = false;
            self.audience_locations = [{data: filteredData}]
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            } else {
              this.loading_location_audience = false;
              this.no_locations_audience = true;
            }
            return []
          });
      }
    },
    resetAudienceLocation() {
      this.$emit('removeAudienceLocationChild', this.selected_audience_location, this.index)
      this.audience_location = ''
      this.selected_audience_location = null
      this.no_locations_audience = false;
    },
    getAudienceLocation(suggestion) {
      this.audience_location = suggestion.item.name
      this.selected_audience_location = suggestion.item
      this.$emit('changeLocationAudienceChild', {location: this.selected_audience_location, percentage: this.percentage_audience_location, key: this.index})
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    changeSlide() {
      this.$emit('changeLocationAudienceChild', {location: this.selected_audience_location, percentage: this.percentage_audience_location, key: this.index})
    },
    setCountryRecommend(location) {
      const data = {
        item: {
          id: location.geonameid,
          name: location.name,
          type: 0
        }
      }
      this.getAudienceLocation(data);
    }
  },
  watch: {
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }
    },
    audience_location_dad(val) {
      this.audience_location = val;
    }
  }
}
</script>

<style lang="scss">
.margin-08 {
  margin-top: 8px;
}
.input-suggestions-audience-locations {
  .form-control {
    background: transparent;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}
.outstanding-input-suggestions {
  height: 21.6px;
  .form-control {
    font-size: 1.286rem;
    font-weight: 800;
    padding: 0;
    height: 21.6px;
    line-height: 1.2;
    margin: 0em 0.2em 0 0.3em;
    color: #0096fe;
  }
}
.h-90 {
  flex-grow: 1;
}
</style>
<style lang="scss" scoped>

.p-custom {
  padding: 0.5rem 1.28rem;
}
.item-list-main-location {
  padding: 0.8em 1.28rem;
  font-family: 'avenir-medium';

  &:hover {
    background-color: #E2E8F0;
    cursor: pointer;
  }
}
</style>